









































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import DeleteAccountDialogViewModel
  from '@/vue-app/view-models/components/user-profile/user-profile-tabs/user-profile-delete-account/delete-account-dialog-view-model';

@Component({
  name: 'DeleteAccountDialog',
  components: {
    AllianzWithdrawalSchedule: () => import('@/vue-app/components/allianz-dashboard/withdrawals/AllianzWithdrawalSchedule.vue'),
    DeleteAccountFullWithdrawal: () => import('@/vue-app/components/user-profile/user-profile-tabs/user-profile-delete-account/DeleteAccountFullWithdrawal.vue'),
    DeleteAccountConfirmation: () => import('@/vue-app/components/user-profile/user-profile-tabs/user-profile-delete-account/DeleteAccountConfirmation.vue'),
    DeleteAccountSummary: () => import('@/vue-app/components/user-profile/user-profile-tabs/user-profile-delete-account/DeleteAccountSummary.vue'),
    DeleteAccountSuccess: () => import('@/vue-app/components/user-profile/user-profile-tabs/user-profile-delete-account/DeleteAccountSuccess.vue'),
  },
})
export default class DeleteAccountDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  delete_account_dialog_view_model = Vue.observable(
    new DeleteAccountDialogViewModel(),
  );

  endProcess() {
    this.synced_is_open = false;
  }
}
