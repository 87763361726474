import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';

export default class DeleteAccountDialogViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.user-profile.user-profile-tabs.delete-account.delete-account-dialog';

  current_step = 1;

  readonly total_steps = 4;

  accreditation_date = '';

  show_stepper = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  menu_steps = this.translate('steps');

  get show_stepper_header() {
    return this.current_step !== 4;
  }

  prevStep = () => {
    if (this.current_step > 0) {
      this.current_step -= 1;
    }
  }

  nextStep = (accreditation_date = '') => {
    if (accreditation_date) {
      this.accreditation_date = accreditation_date;
    }

    if (this.current_step < this.total_steps) {
      this.current_step += 1;
    }
  }

  showStepper = () => {
    this.show_stepper = true;
  }
}
